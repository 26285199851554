var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Regresar a reportes ")],1),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('h3',[_vm._v("Constancia de notas y estudio")]),_c('div',{staticClass:"text-muted text-body-1"},[_vm._v(" Generación de reporte constancia de notas y estudio en la institución. ")]),_vm._m(0),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"¿Qué año desea consultar?","filled":"","rounded":"","items":_vm.years,"item-text":"year","item-value":"year","loading":_vm.yearsLoading,"disabled":_vm.yearsLoading},on:{"click":function($event){return _vm.resetReportParams()},"change":function($event){return _vm.getStudents()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.year))])]):_vm._e()]}}]),model:{value:(_vm.report.params.selectedYear),callback:function ($$v) {_vm.$set(_vm.report.params, "selectedYear", $$v)},expression:"report.params.selectedYear"}})],1)],1),_vm._m(1)],1)])]),_c('v-container',{staticClass:"px-8"},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Código del estudiante","loading":_vm.areStudentsLoading,"disabled":_vm.areStudentsLoading ||
              !_vm.students.length ||
              !_vm.onlyOneStudent ||
              !_vm.yearHasBeenSelected,"filled":"","rounded":"","hide-details":"","clearable":"","items":_vm.students,"item-text":"code","item-value":"code"},on:{"change":function($event){return _vm.get()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.full_name))])]):_vm._e()]}},{key:"item",fn:function({ item, index }){return [_c('v-list-item-content',{staticClass:"student-divider"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{attrs:{"size":"60"}},[_c('img',{attrs:{"src":`${item.photo}`,"alt":`${item.full_name}`}})])],1),_c('v-col',[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.code)+" ")]),_vm._v(" - "),_c('span',{staticClass:"text-uppercase font-weight-medium"},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":`${
                          item.status == 'Activo' ? 'success' : 'red darken-1'
                        }`,"outlined":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.status))])])],1),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('span',[_vm._v(" Grado "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.grade))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Sección "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.section_group))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Especialidad "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.technical_group))])])])],1)],1)],1)]}}]),model:{value:(_vm.report.params.code),callback:function ($$v) {_vm.$set(_vm.report.params, "code", $$v)},expression:"report.params.code"}})],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[(false)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}})],1):_c('div',[_c('div',{staticClass:"pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"},[_c('div',{class:`card-icon-container ${
                  !!_vm.report.params.code ? 'orange' : 'blue-grey'
                } lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`},[_c('v-icon',{attrs:{"color":`${
                    !!_vm.report.params.code ? 'orange' : 'blue-grey'
                  } mx-auto`,"large":""}},[_vm._v("mdi-account-check")])],1),_c('div',{staticClass:"card-info-container ml-5 justify-center"},[_c('p',{class:`ma-0 text-h5 font-weight-bold text-center text-sm-left ${
                    !!_vm.report.params.code ? 'text--primary' : 'text--disabled'
                  } `},[_vm._v(" Constancia de notas ")]),_c('div',[(!!_vm.report.params.code)?_c('v-btn',{staticClass:"mt-2",attrs:{"target":"_blank","small":"","color":"success","dark":"","block":""},on:{"click":function($event){return _vm.generateGradeCertificate()}}},[_vm._v(" Descargar ")]):_vm._e()],1)])])])]),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[(false)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}})],1):_c('div',[_c('div',{staticClass:"pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"},[_c('div',{class:`card-icon-container ${
                  !!_vm.report.params.code ? 'teal' : 'blue-grey'
                } lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`},[_c('v-icon',{attrs:{"large":"","color":`${!!_vm.report.params.code ? 'teal' : 'blue-grey'}`}},[_vm._v("mdi-badge-account-outline")])],1),_c('div',{staticClass:"card-info-container ml-5"},[_c('p',{class:`${
                    !!_vm.report.params.code ? 'text--primary' : 'text--disabled'
                  } ma-0 text-h5 font-weight-bold text-center text-sm-left`},[_vm._v(" Constancia de estudio ")]),(!!_vm.report.params.code)?_c('v-btn',{staticClass:"mt-2",attrs:{"target":"_blank","block":"","small":"","color":"success","dark":""},on:{"click":function($event){return _vm.generateStudiesCertificate()}}},[_vm._v(" Descargar ")]):_vm._e()],1)])])]),_c('p',{staticClass:"text-body-1 font-weight-medium mt-2 mx-8"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("IMPORTANTE")]),_vm._v(", para poder descargar la constancia de notas de un periodo en específico, "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("La primera etapa debe ser menor que la segunda.")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"elevation-2 mb-3 mx-5",attrs:{"disabled":!_vm.report.params.code}},[_c('p',{staticClass:"text-h5 ma-0 pt-4 pl-4 font-weight-medium"},[_vm._v(" Etapas para constancia de notas en un periodo específico ")]),_c('v-divider'),_c('v-container',{staticClass:"pa-4"},[_c('v-row',{staticClass:"align-items-center mb-5"},[_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.isStagesGroupLoading
                          ? 'Cargando etapas...'
                          : 'Primera etapa',"filled":"","rounded":"","disabled":!_vm.report.params.code || _vm.isStagesGroupLoading,"loading":_vm.isStagesGroupLoading,"hide-details":"","items":_vm.stages,"item-text":"stage.name","item-value":"stage_id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.stage.name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.firstStage),callback:function ($$v) {_vm.$set(_vm.report.params, "firstStage", $$v)},expression:"report.params.firstStage"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.isStagesGroupLoading
                          ? 'Cargando etapas...'
                          : 'Segunda etapa',"filled":"","rounded":"","disabled":!_vm.report.params.code || _vm.isStagesGroupLoading,"loading":_vm.isStagesGroupLoading,"hide-details":"","items":_vm.stages,"item-text":"stage.name","item-value":"stage_id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.stage.name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.secondStage),callback:function ($$v) {_vm.$set(_vm.report.params, "secondStage", $$v)},expression:"report.params.secondStage"}})],1)])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(false)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}})],1):_c('div',[_c('div',{staticClass:"pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"},[_c('div',{class:`card-icon-container ${
                  !!_vm.report.params.code && _vm.theresValidStagesSelected
                    ? 'purple'
                    : 'blue-grey'
                } purple lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`},[_c('v-icon',{attrs:{"large":"","color":`${
                    !!_vm.report.params.code && _vm.theresValidStagesSelected
                      ? 'purple'
                      : 'blue-grey'
                  }`}},[_vm._v("mdi-calendar-week")])],1),_c('div',{staticClass:"card-info-container ml-5"},[_c('p',{class:`${
                    !!_vm.report.params.code && _vm.theresValidStagesSelected
                      ? 'text--primary'
                      : 'text--disabled'
                  } ma-0 text-h5 font-weight-bold text-center text-sm-left`},[_vm._v(" Constancia de notas entre periodos ")]),(!!_vm.report.params.code && _vm.theresValidStagesSelected)?_c('v-btn',{staticClass:"mt-2",attrs:{"target":"_blank","block":"","small":"","color":"success","dark":""},on:{"click":function($event){return _vm.generateGradeCertificateInSelectedPeriod()}}},[_vm._v(" Descargar ")]):_vm._e()],1)])])])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-body-1 font-weight-medium mt-10"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("IMPORTANTE")]),_vm._v(", para poder seleccionar un estudiante, "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" PRIMERO debe seleccionar el año de la información que desea consultar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-body-1 font-weight-medium mt-2 mb-n1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("IMPORTANTE")]),_vm._v(", para poder descargar la constancia de notas o estudio, "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" debe ingresar un código de estudiante.")])])
}]

export { render, staticRenderFns }