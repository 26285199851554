<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <!-- end:: "Go Back" button -->
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>Constancia de notas y estudio</h3>
            <div class="text-muted text-body-1">
              Generación de reporte constancia de notas y estudio en la
              institución.
            </div>
            <!-- year selection -->
            <p class="text-body-1 font-weight-medium mt-10">
              <span class="font-weight-bold">IMPORTANTE</span>, para poder
              seleccionar un estudiante,
              <span class="font-weight-bold">
                PRIMERO debe seleccionar el año de la información que desea
                consultar.</span
              >
            </p>

            <v-row>
              <v-col>
                <v-select
                  label="¿Qué año desea consultar?"
                  filled
                  rounded
                  :items="years"
                  item-text="year"
                  item-value="year"
                  :loading="yearsLoading"
                  :disabled="yearsLoading"
                  v-model="report.params.selectedYear"
                  @click="resetReportParams()"
                  @change="getStudents()"
                >
                  <!-- begin::selected grade item -->
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" small v-if="index === 0">
                      <span>{{ item.year }}</span>
                    </v-chip>
                  </template>
                  <!-- end::selected grade item -->
                </v-select>
              </v-col>
            </v-row>

            <!-- student code -->
            <p class="text-body-1 font-weight-medium mt-2 mb-n1">
              <span class="font-weight-bold">IMPORTANTE</span>, para poder
              descargar la constancia de notas o estudio,
              <span class="font-weight-bold">
                debe ingresar un código de estudiante.</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- content -->
      <!-- begin::autocomplete select for student -->
      <v-container class="px-8">
        <v-row>
          <v-col>
            <v-autocomplete
              label="Código del estudiante"
              :loading="areStudentsLoading"
              :disabled="
                areStudentsLoading ||
                !students.length ||
                !onlyOneStudent ||
                !yearHasBeenSelected
              "
              filled
              rounded
              hide-details
              clearable
              :items="students"
              item-text="code"
              item-value="code"
              v-model="report.params.code"
              @change="get()"
            >
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.full_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item, index }">
                <v-list-item-content class="student-divider">
                  <v-row>
                    <v-col cols="1">
                      <v-avatar size="60">
                        <img
                          :src="`${item.photo}`"
                          :alt="`${item.full_name}`"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col>
                      <v-list-item-title>
                        <span class="font-weight-medium">
                          {{ item.code }}
                        </span>
                        -
                        <span class="text-uppercase font-weight-medium">
                          {{ item.full_name }}
                        </span>
                        <v-chip
                          x-small
                          class="ma-2"
                          :color="`${
                            item.status == 'Activo' ? 'success' : 'red darken-1'
                          }`"
                          outlined
                        >
                          <span class="font-weight-bold">{{
                            item.status
                          }}</span>
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1">
                        <span>
                          Grado
                          <strong class="ml-1">{{ item.grade }}</strong>
                        </span>
                        <span class="ml-2">
                          Sección
                          <strong class="ml-1">{{ item.section_group }}</strong>
                        </span>
                        <span class="ml-2">
                          Especialidad
                          <strong class="ml-1">{{
                            item.technical_group
                          }}</strong>
                        </span>
                      </v-list-item-subtitle></v-col
                    >
                  </v-row>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <!-- end::autocomplete select for student -->

      <!-- links -->
      <v-container>
        <v-row>
          <v-col cols="12" md="6" xl="6">
            <div v-if="false">
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </div>
            <div v-else>
              <div
                class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
              >
                <div
                  :class="`card-icon-container ${
                    !!report.params.code ? 'orange' : 'blue-grey'
                  } lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`"
                >
                  <v-icon
                    :color="`${
                      !!report.params.code ? 'orange' : 'blue-grey'
                    } mx-auto`"
                    large
                    >mdi-account-check</v-icon
                  >
                </div>
                <div class="card-info-container ml-5 justify-center">
                  <p
                    :class="`ma-0 text-h5 font-weight-bold text-center text-sm-left ${
                      !!report.params.code ? 'text--primary' : 'text--disabled'
                    } `"
                  >
                    Constancia de notas
                  </p>
                  <div>
                    <v-btn
                      @click="generateGradeCertificate()"
                      target="_blank"
                      class="mt-2"
                      small
                      v-if="!!report.params.code"
                      color="success"
                      dark
                      block
                    >
                      Descargar
                    </v-btn>
                  </div>

                  <!-- <p class="text-overline ma-0 text-center text-sm-left">
                    Total femenino,
                    <span class="font-weight-bold">tercer ciclo</span>
                  </p> -->
                </div>
              </div>
            </div></v-col
          >
          <v-col cols="12" md="6" xl="6">
            <div v-if="false">
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </div>
            <div v-else>
              <div
                class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
              >
                <div
                  :class="`card-icon-container ${
                    !!report.params.code ? 'teal' : 'blue-grey'
                  } lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`"
                >
                  <v-icon
                    large
                    :color="`${!!report.params.code ? 'teal' : 'blue-grey'}`"
                    >mdi-badge-account-outline</v-icon
                  >
                </div>
                <div class="card-info-container ml-5">
                  <p
                    :class="`${
                      !!report.params.code ? 'text--primary' : 'text--disabled'
                    } ma-0 text-h5 font-weight-bold text-center text-sm-left`"
                  >
                    Constancia de estudio
                  </p>
                  <v-btn
                    v-if="!!report.params.code"
                    @click="generateStudiesCertificate()"
                    target="_blank"
                    block
                    class="mt-2"
                    small
                    color="success"
                    dark
                  >
                    Descargar
                  </v-btn>
                </div>
              </div>
            </div></v-col
          >
          <p class="text-body-1 font-weight-medium mt-2 mx-8">
            <span class="font-weight-bold">IMPORTANTE</span>, para poder
            descargar la constancia de notas de un periodo en específico,
            <span class="font-weight-bold"
              >La primera etapa debe ser menor que la segunda.</span
            >
          </p>
        </v-row>

        <v-row>
          <v-col>
            <!-- begin::filters for report generation-->
            <v-card
              class="elevation-2 mb-3 mx-5"
              :disabled="!report.params.code"
            >
              <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                Etapas para constancia de notas en un periodo específico
              </p>
              <v-divider></v-divider>
              <!-- begin::Filters combos -->
              <v-container class="pa-4">
                <v-row class="align-items-center mb-5">
                  <!-- begin::select first stage filter-->
                  <v-col cols="12" sm="6" class="my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- begin:First stage combo -->
                      <v-select
                        :label="
                          isStagesGroupLoading
                            ? 'Cargando etapas...'
                            : 'Primera etapa'
                        "
                        filled
                        rounded
                        :disabled="!report.params.code || isStagesGroupLoading"
                        :loading="isStagesGroupLoading"
                        hide-details
                        :items="stages"
                        item-text="stage.name"
                        item-value="stage_id"
                        clearable
                        v-model="report.params.firstStage"
                      >
                        <!-- begin::selected grade item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.stage.name }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected grade item -->
                      </v-select>
                      <!-- end:First stage combo -->
                    </div>
                  </v-col>
                  <!-- end::select first stage filter-->

                  <!-- begin::select first stage filter-->
                  <v-col cols="12" sm="6" class="my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- begin:First stage combo -->
                      <v-select
                        :label="
                          isStagesGroupLoading
                            ? 'Cargando etapas...'
                            : 'Segunda etapa'
                        "
                        filled
                        rounded
                        :disabled="!report.params.code || isStagesGroupLoading"
                        :loading="isStagesGroupLoading"
                        hide-details
                        :items="stages"
                        item-text="stage.name"
                        item-value="stage_id"
                        clearable
                        v-model="report.params.secondStage"
                      >
                        <!-- begin::selected grade item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.stage.name }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected grade item -->
                      </v-select>
                      <!-- end:First stage combo -->
                    </div>
                  </v-col>
                  <!-- end::select first stage filter-->
                </v-row>
              </v-container>
              <!-- end::Filters combos -->
            </v-card>
            <!-- end::filters for report generation-->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <div v-if="false">
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </div>
            <div v-else>
              <div
                class="pa-5 d-flex rounded-lg dashboard-card flex-column flex-sm-row align-center"
              >
                <div
                  :class="`card-icon-container ${
                    !!report.params.code && theresValidStagesSelected
                      ? 'purple'
                      : 'blue-grey'
                  } purple lighten-4 d-flex rounded-circle pa-5 mb-3 mb-sm-0`"
                >
                  <v-icon
                    large
                    :color="`${
                      !!report.params.code && theresValidStagesSelected
                        ? 'purple'
                        : 'blue-grey'
                    }`"
                    >mdi-calendar-week</v-icon
                  >
                </div>
                <div class="card-info-container ml-5">
                  <p
                    :class="`${
                      !!report.params.code && theresValidStagesSelected
                        ? 'text--primary'
                        : 'text--disabled'
                    } ma-0 text-h5 font-weight-bold text-center text-sm-left`"
                  >
                    Constancia de notas entre periodos
                  </p>
                  <v-btn
                    v-if="!!report.params.code && theresValidStagesSelected"
                    @click="generateGradeCertificateInSelectedPeriod()"
                    target="_blank"
                    block
                    class="mt-2"
                    small
                    color="success"
                    dark
                  >
                    Descargar
                  </v-btn>
                </div>
              </div>
            </div></v-col
          >
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import settingsRepository from "@/repositories/settingsRepository";
import studentRepository from "@/repositories/studentRepository";
import ApiService from "@/core/services/api.service";
import stageRepository from "@/repositories/stageRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "GradeCertificate",
  title: "Constancia de notas | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      { title: "Constancia de notas", route: "grade_certificate" },
    ]);
    this.getStudents();
    this.loadYears();
  },
  data() {
    return {
      years: [{ year: 2022 }, { year: 2023 }],
      yearsLoading: false,
      filteredStudent: [],
      isStagesGroupLoading: false,
      onlyOneStudent: true,
      students: [],
      stages: [],
      encryptedYear: null,
      areStudentsLoading: false,
      report: {
        params: {
          selectedYear: 2022,
        },
      },
    };
  },
  methods: {
    get() {
      if (!this.report.params.code) {
        return;
      }
      this.stages = [];
      this.loadStages();
    },
    resetReportParams() {
      this.report.params = {};
    },
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformationIncludingDisabled({
          headers: {
            year: `${this.report.params.selectedYear}`,
          },
        })
        .then(async ({ data }) => {
          this.students = data;
          this.encryptedYear = await this.encryptCode(
            this.report.params.selectedYear
          );
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },

    async encryptCode(code) {
      try {
        // Se hace la petición para encriptar el id del grupo académico
        const { data: codeEncrypted } = await ApiService.get(
          `${this.reportsBaseURL}/version/u/${code}`
        );
        return codeEncrypted;
      } catch (error) {
        // Se settea la variable true para ocultar el botón
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los datos desde el servidor",
        });
        return;
      }
    },

    loadStages() {
      this.isStagesGroupLoading = true;

      let student = this.students.find(
        (student) => student.code == this.report.params.code
      );

      let allStages = [];

      stageRepository
        .stageByAcademicLevel(student.grade_id, 8, {
          headers: {
            year: `${this.report.params.selectedYear}`,
          },
        })
        .then(({ data }) => {
          allStages = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          allStages.forEach((stage) => {
            if (stage.stage_id < 8) {
              this.stages.push(stage);
            }
          });
          this.isStagesGroupLoading = false;
        });
    },

    async generateGradeCertificate() {
      let url =
        await `${this.reportsBaseURL}/proofs/proof-scores/${this.report.params.code}/year/${this.encryptedYear}`;
      window.open(url, "_blank");
    },

    async generateGradeCertificateInSelectedPeriod() {
      let url =
        await `${this.reportsBaseURL}/proofs/proof-scores/${this.report.params.code}/stages/${this.report.params.firstStage}/${this.report.params.secondStage}/year/${this.encryptedYear}`;
      window.open(url, "_blank");
    },

    async generateStudiesCertificate() {
      let url =
        await `${this.reportsBaseURL}/proofs/study-proof/${this.report.params.code}/year/${this.encryptedYear}`;
      window.open(url, "_blank");
    },

    loadYears() {
      this.yearsLoading = true;
      settingsRepository
        .getAllYears()
        .then(({ data }) => {
          this.years = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.yearsLoading = false;
        });
    },
  },

  computed: {
    reportsBaseURL() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    yearHasBeenSelected() {
      return !!this.report.params.selectedYear;
    },
    theresValidStagesSelected() {
      if (this.report.params.firstStage && this.report.params.secondStage) {
        return this.report.params.secondStage >= this.report.params.firstStage;
      }
      return false;
    },
  },
};
</script>
<style>
.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 75px;
  max-width: 75px;
}

.student-divider {
  border-bottom: 1px solid rgb(202, 202, 202);
}
</style>
